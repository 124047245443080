import { configureStore } from "@reduxjs/toolkit";
import tokenReducer from './reducers/tokenSlice';
import uidReducer from './reducers/uidSlice';

export const store = configureStore({
  reducer: {
    token: tokenReducer,
    uid: uidReducer,
  },
});
