import { createSlice } from '@reduxjs/toolkit';

const uidSlice = createSlice({
  name: 'uid',
  initialState: {
    value: 'none',
  },
  reducers: {
    saveUid: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { saveUid } = uidSlice.actions;

export default uidSlice.reducer;
