import { createSlice } from '@reduxjs/toolkit';

const tokenSlice = createSlice({
  name: 'token',
  initialState: {
    value: 'none',
  },
  reducers: {
    saveToken: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { saveToken } = tokenSlice.actions;

export default tokenSlice.reducer;
