import React, { Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./firebase-config";

import { Dots } from "./components/spinner";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import { useDispatch, connect } from "react-redux";
import { saveToken } from "./redux/reducers/tokenSlice";
import { saveUid } from "./redux/reducers/uidSlice";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const mapStateToProps = (state) => ({
  token: state.token.value,
});

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = ({ token }) => {

  const dispatch = useDispatch();
  const auth = getAuth();
  onAuthStateChanged(auth, (u) => {
    if (!u?.accessToken) {
      saveToken("none");
      saveUid("none");
      return;
    }
    if (process.env.NODE_ENV === "development") {
      console.log(u.accessToken);
    }
    dispatch(saveToken(u.accessToken));
    dispatch(saveUid(u.uid));
  });

  const client = new ApolloClient({
    uri: process.env.REACT_APP_API_URL + "/graphql",
    cache: new InMemoryCache(),
    headers: {
      Authorization: token,
    },
  });

  if (!token) {
    return <Dots />;
  }
  return (
    <ApolloProvider client={client}>
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              element={<Register />}
            />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route
              path="*"
              name="Home"
              element={token !== "none" ? <DefaultLayout /> : <Login />}
            />
          </Routes>
        </Suspense>
      </HashRouter>
    </ApolloProvider>
  );
};

export default connect(mapStateToProps)(App);
