import firebase from "firebase/compat/app";

let firebaseConfig = {};
/*if (process.env.NODE_ENV === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyCckqJBuc3q3LtSM7tQ9i6OU6PqbDHbVCM",
    authDomain: "babaiola-application.firebaseapp.com",
    projectId: "babaiola-application",
    storageBucket: "babaiola-application.appspot.com",
    messagingSenderId: "190705323945",
    appId: "1:190705323945:web:58bd355cc907a635c2feb8",
    measurementId: "G-25KQ801SE5",
  };
} else*/
if (process.env.NODE_ENV === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyDUemxAzeRPBs0FfLjJgkDGjqP9yuJ6fSc",
    authDomain: "dev-babaiola-application.firebaseapp.com",
    projectId: "dev-babaiola-application",
    storageBucket: "dev-babaiola-application.appspot.com",
    messagingSenderId: "365818562069",
    appId: "1:365818562069:web:7487ffd387f646f402701c",
    measurementId: "G-YC9XHQV9L9",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyDUemxAzeRPBs0FfLjJgkDGjqP9yuJ6fSc",
    authDomain: "dev-babaiola-application.firebaseapp.com",
    projectId: "dev-babaiola-application",
    storageBucket: "dev-babaiola-application.appspot.com",
    messagingSenderId: "365818562069",
    appId: "1:365818562069:web:7487ffd387f646f402701c",
    measurementId: "G-YC9XHQV9L9",
  };
}

firebase.initializeApp(firebaseConfig);

export default firebase;
